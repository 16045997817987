import React from "react"

export const SpotifyThin = ({ embed }) => {
   return (
      <div
         className="spotifyThin"
         dangerouslySetInnerHTML={{ __html: embed }}
      />
   )
}

export const Socials = ({ links }) => {
   return (
      <ul className="socialLinks">
         {Object.keys(links).map((key, index) => (
            <li className={key} key={key}>
               <a href={links[key]} className={`fa fa-${key}`}>
                  {}
               </a>
            </li>
         ))}
      </ul>
   )
}

export const Playable = props => {
   const playableTrackInfo = {
      url: props.trackURL,
      name: props.trackName,
      artist: props.artistName,
   }

   return (
      <div
         className={`${props.playerStyles} player`}
         onClick={props.clickPlayable.bind(this, playableTrackInfo)}
      >
         {props.children}
      </div>
   )
}
