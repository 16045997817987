// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-me-js": () => import("../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-featured-work-js": () => import("../src/pages/featured-work.js" /* webpackChunkName: "component---src-pages-featured-work-js" */),
  "component---src-pages-full-discography-js": () => import("../src/pages/full-discography.js" /* webpackChunkName: "component---src-pages-full-discography-js" */),
  "component---src-pages-httparty-js": () => import("../src/pages/httparty.js" /* webpackChunkName: "component---src-pages-httparty-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-projects-js": () => import("../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-bjonthedaily-js": () => import("../src/pages/projects/bjonthedaily.js" /* webpackChunkName: "component---src-pages-projects-bjonthedaily-js" */),
  "component---src-pages-projects-design-and-artwork-js": () => import("../src/pages/projects/design-and-artwork.js" /* webpackChunkName: "component---src-pages-projects-design-and-artwork-js" */),
  "component---src-pages-projects-electronics-js": () => import("../src/pages/projects/electronics.js" /* webpackChunkName: "component---src-pages-projects-electronics-js" */),
  "component---src-pages-projects-music-videos-js": () => import("../src/pages/projects/music-videos.js" /* webpackChunkName: "component---src-pages-projects-music-videos-js" */),
  "component---src-pages-projects-to-kill-a-king-js": () => import("../src/pages/projects/to-kill-a-king.js" /* webpackChunkName: "component---src-pages-projects-to-kill-a-king-js" */),
  "component---src-pages-projects-web-development-js": () => import("../src/pages/projects/web-development.js" /* webpackChunkName: "component---src-pages-projects-web-development-js" */)
}

